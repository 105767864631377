
import './style.css'
import './files/CSS/Menu/desktopmenu.css'

import './files/CSS/SettingsCSS/start.css'
import './files/CSS/SettingsCSS/loading.css'

import './files/CSS/Resizen/mobile.css'
import './files/CSS/Resizen/iframe.css'

import './files/CSS/Buttons/animations.css'
import './files/CSS/Buttons/wrapper.css'
import './files/CSS/Buttons/checkbox.css'

import './files/CSS/Menu/menu.css'

import './files/JS/Buttons/SelectedButton.js'
import './files/JS/Buttons/YesNoButtons.js'

import './files/JS/SettingsJS/Start.js'
import './files/JS/Mobile/Menu.js'

import { OrbitControls  } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { DecalGeometry } from 'three/examples/jsm/geometries/DecalGeometry.js';



import Stats from 'stats.js'
import * as THREE from 'three';
import * as lil from 'lil-gui'


/*******************
 Default
*******************/

const bar = document.querySelector('.loading-bar')


$(".circle, .background").click(function () {
   start()

   document.getElementById("start").style.display = 'none'
   document.getElementById("startbutton").style.display = 'none'

   document.getElementById("loading").style.display = 'block'
   bar.style.display = "block"

   const animations = document.querySelectorAll('[data-animation');
    animations.forEach(animation => {
      const paused = animation.style.animationPlayState || 'paused';
      animation.style.animationPlayState = paused === 'paused' ? 'running' : 'paused';
    })
})

function start(){



/*******************
 Default
*******************/


// FPS Meter
const stats = new Stats()
stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
//  document.body.appendChild(stats.dom)

// Function Render
function render(){
      camera.updateProjectionMatrix();
   }

   
 /******
   Base
 ******/


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()


 /***********
   Start up
 ***********/


     // Loading Manager
const loadingBarContainer = document.querySelector('.loading-bar-container')
const loadingBarElement = document.querySelector('.loading-bar')
const loadingLogoElement = document.querySelector('.intro')

const loadingText = document.querySelector('#loading')
const loadingSlowerText = document.querySelector('#slowLoading')

const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        // Wait a little        

        window.setTimeout(() =>
        {
        // Activate Theme
        mooguelogo()

        // Update loadingBarContainer
        loadingBarContainer.classList.add('ended')
        loadingBarContainer.style.transform = ''

        loadingText.style.display = 'none'        
        loadingLogoElement.classList.add('ended')

        document.getElementById("loading").style.display = 'none'

        loadingBarElement.style.display = 'none'
      //   loadingSlowerText.style.display = 'none'
         
      //   clearTimeout(tekstladen)

        }, 500
        )
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        // Calculate the progress and update the loadingBarElement
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`

      //   const tekstladen = setTimeout(() => {
      //   loadingSlowerText.style.display = 'block'
   // }, 5000)
   })


 /*******
   Model
 *******/
    

   // const geometry = new THREE.PlaneGeometry( 0.75, 0.50 );
   // const material = new THREE.MeshPhongMaterial( {color: 0x002647, side: THREE.BackSide} );
   // const hidedecal = new THREE.Mesh( geometry, material );
   // hidedecal.position.setX( 0.10 );
   // hidedecal.position.setY( 0.9 );
   // // hidedecal.position.setZ( 0 );
   // hidedecal.rotateY(1.5)
   // scene.add( hidedecal );


  /***********
     Textures 
  ***********/


  // Loader
  const textureLoader = new THREE.TextureLoader();

  // Decal Textures
  const texture1 = textureLoader.load( "/textures/decal/logos/dmoogue.png" );
  const texture2 = textureLoader.load( "/textures/decal/logos/dnyc.png" );

  const decalnycNormal = textureLoader.load( "/textures/decal/DecalNYCNormal.png" );
  const decalnycBump = textureLoader.load("/textures/decal/DecalNYCBump.png");

  const decalmooNormal = textureLoader.load( "/textures/decal/DecalMOOGUENormal.png" );
  const decalmooBump = textureLoader.load("/textures/decal/DecalMOOGUEBump.png");


  // Model Textures
  const colorMap = textureLoader.load('/textures/pet/Color.png')
  const normalMap = textureLoader.load('/textures/pet/NormalColor.png')

  colorMap.generateMimaps = false
  colorMap.minFilter = THREE.NearestFilter
  colorMap.magFilter = THREE.NearestFilter
//   colorMap.encoding = THREE.sRGBEncoding;
//   colorMap.flipY = false;

const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

let loadedModel
const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader)		
gltfLoader.load(
   '/models/glTF/BaseballCap.gltf',
    (gltf) =>
    {
       loadedModel = gltf.scene.children[0];
       loadedModel.material = new THREE.MeshPhongMaterial({
        specular: 0x111111,
              map: colorMap,
              normalMap: normalMap,
              normalScale: new THREE.Vector2(1, 1),
              shininess: 25
       });
     
       // Names
       gltf.scene.name = 'pet'

       scene.add( gltf.scene );
       gltf.scene.scale.set(1, 1, 1)
       console.log( gltf.scene )

       // Color
       gltf.scene.children[0].children[0].material.color=new THREE.Color( '#002647' ) // Stitches
       gltf.scene.children[0].children[1].material.color=new THREE.Color( '#002647' ) // Side
       gltf.scene.children[0].children[2].material.color=new THREE.Color( '#00AAED' ) // Visor
       gltf.scene.children[0].children[3].material.color=new THREE.Color( '#00AAED' ) // Crown Stitches
       gltf.scene.children[0].children[4].material.color=new THREE.Color( '#00AAED' ) // Sweatband
       gltf.scene.children[0].children[5].material.color=new THREE.Color( '#00AAED' ) // Top Button
       gltf.scene.children[0].children[6].material.color=new THREE.Color( '#002647' ) // Front

       render();

       gltf.scene.children[0].traverse((o) => {
           if (o.isMesh) {
             o.castShadow = true;
             o.receiveShadow = true;
           }
       });

       // Positioning
    gltf.scene.position.setX( 0 );
    gltf.scene.position.setY( 0 );
    gltf.scene.position.setZ( 0 );

      //   // Resize Laptop
      //   function ResizeL(laptop) {
      //      if (laptop.matches) { // If media query matches
      //         gltf.scene.position.setY( 0 );
      //         gltf.scene.position.setX( 0 );
      //      }
      //   }

        // Resize Ipad
        function ResizeI(ipad) {
           if (ipad.matches) { // If media query matches
              gltf.scene.position.setY( 0.1 );
              gltf.scene.position.setX( -0.6 );
           } 
           else {
            gltf.scene.position.setX( 0 );
            gltf.scene.position.setY( 0 );
           }
        }

           // Resize Mobile
        function ResizeM(mobiel) {
           if (mobiel.matches) { // If media query matches
              gltf.scene.position.setY( 0.3 );
              gltf.scene.position.setX( -0.5 );
           } 
           else {
            gltf.scene.position.setX( 0 );
            gltf.scene.position.setY( 0 );
           }
        }



   // var laptop = window.matchMedia("(max-width: 1024px)")
   // ResizeL(laptop)
   // laptop.addListener(ResizeL)

   var ipad = window.matchMedia("(max-width: 768px)")
   ResizeI(ipad) // Call listener function at run time
   ipad.addListener(ResizeI) // Attach listener function on state changes

   var mobiel = window.matchMedia("(max-width: 480px)")
   ResizeM(mobiel) // Call listener function at run time
   mobiel.addListener(ResizeM) // Attach listener function on state changes
   });
  

 /*************
   Environment
 *************/


 /*************
 floor color: #6a6a6a
 fog color: #8B8B8B
 background color: #8B8B8B
 *************/


ColorLighting()


 /*******
   Sizes
 *******/


function testing(sizesfix) {
   if (sizesfix.matches) { // If media query matches
        const sizesfixing = {
           width: window.innerWidth + 435.6,
           height: window.innerHeight      
        }

     return sizesfixing
   } else {

     const sizesfixing = {
        width: window.innerWidth,
        height: window.innerHeight      
     }
     return sizesfixing
   }

}
 var sizesfix = window.matchMedia("(min-width: 1024px)")
 testing(sizesfix) // Call listener function at run time
 sizesfix.addListener(testing) // Attach listener function on state changes

  const sizes = testing(sizesfix)

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth + 435.6,
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
   //  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 0.5))
   renderer.setPixelRatio(Math.max(1, window.devicePixelRatio / 2))
})


 /********
   Camera
 ********/


// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set( 2.2 , 0.5 ,0.5)
camera.lookAt(scene)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
 controls.target.set( 0, 0.1 , 0 )
 controls.minDistance = 1.6;
 controls.maxDistance = 2.5;
 controls.maxPolarAngle = 1.55;
 controls.minPolarAngle = 0.95;
 controls.enablePan = false;
 controls.enableDamping = true; 
 controls.dampingFactor = 0.08;
 controls.autoRotate = true; 
 controls.autoRotateSpeed = 0.75; // 30


 /**********
   Renderer
 **********/


 const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias : false
 })

 renderer.outputEncoding = THREE.sRGBEncoding
 //  renderer.physicallyCorrectLights = true

 // renderer.toneMapping = THREE.LinearToneMapping

 renderer.shadowMap.enabled = true
 renderer.shadowMap.type = THREE.PCFSoftShadowMap
  
 

 renderer.setSize(sizes.width, sizes.height)
 renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


 /*********
   Animate
 *********/
 

 const tick = () =>
 {
     stats.begin()
    
     // Update objects

     stats.update();
        
     // Update controls
     controls.update()
    
     // Render
     renderer.render(scene, camera, canvas)
 
     // Call tick again on the next frame
     window.requestAnimationFrame(tick)

     stats.end()
 }

   tick() 


  /****************** 
  Informatie over de knoppen/onderdelen

  Count 0 = Klep (Visor)
  Count 1 = Voorkant (Front)
  Count 2 = Gaas (Side)
  Count 3 = Dop (Top Button)
  Count 4 = Stitches (Stitches)
  Count 5 = Lijnen (Grown Stitches)
  Count 6 = Onderste lijn (Sweatband)

  children[0] = Stitches
  children[1] = Side
  children[2] = Visor
  children[3] = Grown Stitches
  children[4] = Sweatband
  children[5] = Top Button
  children[6] = Front

  Informatie over de knoppen/onderdelen
  ******************/


  /*******
   Colors 
  *******/


var childrenID = 2

function changeChildrenID(count) {
  switch(count){
     case 0:
        childrenID = 2
     break;
     case 1:
        childrenID = 6
     break;
     case 2:
        childrenID = 1
     break;
     case 3:
        childrenID = 5 
     break;
     case 4:
        childrenID = 0
     break;
     case 5: 
        childrenID = 3
     break;
     case 6:
        childrenID = 4
     break;
  }
  if (document.querySelectorAll("[data-radio-selected='true']").length){
     document.querySelectorAll("[data-radio-selected='true']")[0].dataset.radioSelected = 'false'
      //   console.log(scene.getObjectByName('pet').children[0].children[childrenID].material.color.getHexString())
  }
  if (document.querySelectorAll("[data-color='#" + scene.getObjectByName('pet').children[0].children[childrenID].material.color.getHexString().toUpperCase() + "']").length){
     document.querySelectorAll("[data-color='#" + scene.getObjectByName('pet').children[0].children[childrenID].material.color.getHexString().toUpperCase() + "']")[0].dataset.radioSelected = 'true'
      //   console.log(document.querySelectorAll("[data-color='#" + scene.getObjectByName('pet').children[0].children[childrenID].material.color.getHexString().toUpperCase() + "']"))
  }
}

function klep(hex, childrenID)  {
   scene.getObjectByName('pet').children[0].children[childrenID].material.color = new THREE.Color( hex )
}
document.getElementById('k-rood').onclick= () =>{klep('#660300', childrenID)} 
document.getElementById('k-oranje').onclick= () =>{klep('#CB6015', childrenID)} 
document.getElementById('k-geel').onclick= () =>{klep('#E0A526', childrenID)} 
document.getElementById('k-beige').onclick= () =>{klep('#BF9972', childrenID)} 
document.getElementById('k-donkergroen').onclick= () =>{klep('#0F3325', childrenID)} 
document.getElementById('k-lichtgroen').onclick= () =>{klep('#409140', childrenID)}
document.getElementById('k-pinkish').onclick= () =>{klep('#D2ABDB', childrenID)} 
document.getElementById('k-paars').onclick= () =>{klep('#4B365F', childrenID)}  
document.getElementById('k-donkerblauw').onclick= () =>{klep('#002647', childrenID)} 
document.getElementById('k-lichtblauw').onclick= () =>{klep('#00AAED', childrenID)}
document.getElementById('k-lichtgrijs').onclick= () =>{klep('#78807A', childrenID)} 
document.getElementById('k-donkergrijs').onclick= () =>{klep('#292C33', childrenID)}  

function voorkant(hex, childrenID) {
   scene.getObjectByName('pet').children[0].children[childrenID].material.color = new THREE.Color( hex )
}
document.getElementById('k-rood').onclick= () =>{voorkant('#660300', childrenID)} 
document.getElementById('k-oranje').onclick= () =>{voorkant('#CB6015', childrenID)} 
document.getElementById('k-geel').onclick= () =>{voorkant('#E0A526', childrenID)} 
document.getElementById('k-beige').onclick= () =>{voorkant('#BF9972', childrenID)} 
document.getElementById('k-donkergroen').onclick= () =>{voorkant('#0F3325', childrenID)} 
document.getElementById('k-lichtgroen').onclick= () =>{voorkant('#409140', childrenID)}
document.getElementById('k-pinkish').onclick= () =>{voorkant('#D2ABDB', childrenID)} 
document.getElementById('k-paars').onclick= () =>{voorkant('#4B365F', childrenID)}  
document.getElementById('k-donkerblauw').onclick= () =>{voorkant('#002647', childrenID)} 
document.getElementById('k-lichtblauw').onclick= () =>{voorkant('#00AAED', childrenID)}
document.getElementById('k-lichtgrijs').onclick= () =>{voorkant('#78807A', childrenID)} 
document.getElementById('k-donkergrijs').onclick= () =>{voorkant('#292C33', childrenID)}  

function gaas(hex, childrenID) {
   scene.getObjectByName('pet').children[0].children[childrenID].material.color  =new THREE.Color( hex )
}
document.getElementById('k-rood').onclick= () =>{gaas('#660300', childrenID)} 
document.getElementById('k-oranje').onclick= () =>{gaas('#CB6015', childrenID)} 
document.getElementById('k-geel').onclick= () =>{gaas('#E0A526', childrenID)} 
document.getElementById('k-beige').onclick= () =>{gaas('#BF9972', childrenID)} 
document.getElementById('k-donkergroen').onclick= () =>{gaas('#0F3325', childrenID)} 
document.getElementById('k-lichtgroen').onclick= () =>{gaas('#409140', childrenID)}
document.getElementById('k-pinkish').onclick= () =>{gaas('#D2ABDB', childrenID)} 
document.getElementById('k-paars').onclick= () =>{gaas('#4B365F', childrenID)}  
document.getElementById('k-donkerblauw').onclick= () =>{gaas('#002647', childrenID)} 
document.getElementById('k-lichtblauw').onclick= () =>{gaas('#00AAED', childrenID)}
document.getElementById('k-lichtgrijs').onclick= () =>{gaas('#78807A', childrenID)} 
document.getElementById('k-donkergrijs').onclick= () =>{gaas('#292C33', childrenID)}  

function dop(hex, childrenID) {
   scene.getObjectByName('pet').children[0].children[childrenID].material.color = new THREE.Color( hex )
}
document.getElementById('k-rood').onclick= () =>{dop('#660300', childrenID)} 
document.getElementById('k-oranje').onclick= () =>{dop('#CB6015', childrenID)} 
document.getElementById('k-geel').onclick= () =>{dop('#E0A526', childrenID)} 
document.getElementById('k-beige').onclick= () =>{dop('#BF9972', childrenID)} 
document.getElementById('k-donkergroen').onclick= () =>{dop('#0F3325', childrenID)} 
document.getElementById('k-lichtgroen').onclick= () =>{dop('#409140', childrenID)}
document.getElementById('k-pinkish').onclick= () =>{dop('#D2ABDB', childrenID)} 
document.getElementById('k-paars').onclick= () =>{dop('#4B365F', childrenID)}  
document.getElementById('k-donkerblauw').onclick= () =>{dop('#002647', childrenID)} 
document.getElementById('k-lichtblauw').onclick= () =>{dop('#00AAED', childrenID)}
document.getElementById('k-lichtgrijs').onclick= () =>{dop('#78807A', childrenID)} 
document.getElementById('k-donkergrijs').onclick= () =>{dop('#292C33', childrenID)}   

function stitches(hex, childrenID) {
  scene.getObjectByName('pet').children[0].children[childrenID].material.color = new THREE.Color( hex )
}
document.getElementById('k-rood').onclick= () =>{stitches('#660300', childrenID)} 
document.getElementById('k-oranje').onclick= () =>{stitches('#CB6015', childrenID)} 
document.getElementById('k-geel').onclick= () =>{stitches('#E0A526', childrenID)} 
document.getElementById('k-beige').onclick= () =>{stitches('#BF9972', childrenID)} 
document.getElementById('k-donkergroen').onclick= () =>{stitches('#0F3325', childrenID)} 
document.getElementById('k-lichtgroen').onclick= () =>{stitches('#409140', childrenID)}
document.getElementById('k-pinkish').onclick= () =>{stitches('#D2ABDB', childrenID)} 
document.getElementById('k-paars').onclick= () =>{stitches('#4B365F', childrenID)}  
document.getElementById('k-donkerblauw').onclick= () =>{stitches('#002647', childrenID)} 
document.getElementById('k-lichtblauw').onclick= () =>{stitches('#00AAED', childrenID)}
document.getElementById('k-lichtgrijs').onclick= () =>{stitches('#78807A', childrenID)} 
document.getElementById('k-donkergrijs').onclick= () =>{stitches('#292C33', childrenID)}  

function SweatBand(hex, childrenID) {
  scene.getObjectByName('pet').children[0].children[childrenID].material.color=new THREE.Color( hex )
}
document.getElementById('k-rood').onclick= () =>{SweatBand('#660300', childrenID)} 
document.getElementById('k-oranje').onclick= () =>{SweatBand('#CB6015', childrenID)} 
document.getElementById('k-geel').onclick= () =>{SweatBand('#E0A526', childrenID)} 
document.getElementById('k-beige').onclick= () =>{SweatBand('#BF9972', childrenID)} 
document.getElementById('k-donkergroen').onclick= () =>{SweatBand('#0F3325', childrenID)} 
document.getElementById('k-lichtgroen').onclick= () =>{SweatBand('#409140', childrenID)}
document.getElementById('k-pinkish').onclick= () =>{SweatBand('#D2ABDB', childrenID)} 
document.getElementById('k-paars').onclick= () =>{SweatBand('#4B365F', childrenID)}  
document.getElementById('k-donkerblauw').onclick= () =>{SweatBand('#002647', childrenID)} 
document.getElementById('k-lichtblauw').onclick= () =>{SweatBand('#00AAED', childrenID)}
document.getElementById('k-lichtgrijs').onclick= () =>{SweatBand('#78807A', childrenID)} 
document.getElementById('k-donkergrijs').onclick= () =>{SweatBand('#292C33', childrenID)}  

function CrownStitches(hex, childrenID) {
  scene.getObjectByName('pet').children[0].children[childrenID].material.color = new THREE.Color( hex )
}
document.getElementById('k-rood').onclick= () =>{CrownStitches('#660300', childrenID)} 
document.getElementById('k-oranje').onclick= () =>{CrownStitches('#CB6015', childrenID)} 
document.getElementById('k-geel').onclick= () =>{CrownStitches('#E0A526', childrenID)} 
document.getElementById('k-beige').onclick= () =>{CrownStitches('#BF9972', childrenID)} 
document.getElementById('k-donkergroen').onclick= () =>{CrownStitches('#0F3325', childrenID)} 
document.getElementById('k-lichtgroen').onclick= () =>{CrownStitches('#409140', childrenID)}
document.getElementById('k-pinkish').onclick= () =>{CrownStitches('#D2ABDB', childrenID)} 
document.getElementById('k-paars').onclick= () =>{CrownStitches('#4B365F', childrenID)}  
document.getElementById('k-donkerblauw').onclick= () =>{CrownStitches('#002647', childrenID)} 
document.getElementById('k-lichtblauw').onclick= () =>{CrownStitches('#00AAED', childrenID)}
document.getElementById('k-lichtgrijs').onclick= () =>{CrownStitches('#78807A', childrenID)} 
document.getElementById('k-donkergrijs').onclick= () =>{CrownStitches('#292C33', childrenID)}  


  /*****************
   Next/Prev Button 
  *****************/


   var parts = ["Visor", "Front", "Side", "Top Button", "Stitches", "Crown Stitches", "Sweatband"];

  //  setInterval(() => {
  //    console.log(parts)
  //  }, 1000)

   var count = 0
   $("#parts").html(parts[0]);
  
  
   $("#rightb").click(function() {
     count = (count + 1)
     changeChildrenID(count)
     $("#leftb").css("display", "block")
     $("#parts").html(parts[count])
     if (count >= 6) {
        $("#rightb").hide();
        $("#leftb").click(function() {
        $("#rightb").show()
        })
     }
   });
  
   $("#leftb").click(function() {
     count = (count - +1)
     changeChildrenID(count)
     $("#parts").html(parts[count])
     if (count == 0) {
        $("#leftb").css("display", "none")
        $("#rightb").click(function() {
        $("#leftb").css("display", "block")
        })
     }
   });
  

  /**************
   Logo Buttons
  **************/

   var moo = $("#l-moogue"), nyc = $("#l-nyc");
   
  
   moo.click(function() {

   console.log(this.id)
   
      
      const decalMaterial = new THREE.MeshPhongMaterial({
            map: texture1,
            normalMap: decalmooNormal,
            normalScale: new THREE.Vector2(1, 1),
            bumpMap: decalmooBump,
         specular: 0x444400,
         shininess: 25,
         transparent: true,
         depthTest: true,
         depthWrite: true,
         polygonOffset: true,
         polygonOffsetFactor: -4,
         wireframe: false,
      });
   
   function changingPosition(positioning) {
         if (positioning.matches) { // If media query matches
         const positioning = new THREE.Vector3(0.5, 0.925, -0.005);

      
         return positioning
         } else {
      
            const positioning = new THREE.Vector3(0.8, 0.66, 0.0014);

         return positioning
         }
      
      }

      var positioning = window.matchMedia("(max-width: 480px)")
      changingPosition(positioning) // Call listener function at run time
      positioning.addListener(changingPosition) // Attach listener function on state changes


   const position = changingPosition(positioning)
   const orientation = new THREE.Euler(-1.57, -1.57, -1.57, 'XYZ');
   const size = new THREE.Vector3(0.5, 0.5, 0.5);

   const material = decalMaterial.clone();
      material.color.setHex(0xffffff);

   const m = new THREE.Mesh(new DecalGeometry(loadedModel.children[6], position, orientation, size), material);
   const s = new THREE.Mesh(new DecalGeometry(loadedModel.children[0], position, orientation, size), material);

   const decals = [];
      decals.push(m);
         scene.add(m);

      decals.push(s);
         scene.add(s);
         
   $("#l-moogue").click(function () {
      material.map = texture1
      material.normalMap = decalmooNormal
      material.bumpMap = decalmooBump
      material.needsUpdate = true;                                    
   })

   $("#l-nyc").click(function () {
      material.map = texture2
      material.normalMap = decalnycNormal
      material.bumpMap = decalnycBump
      material.needsUpdate = true;
   })

   // Logo Colors
   $("#l-zwart").click(function () {
      material.color.setHex(0x0d0d0d)
      material.needsUpdate = true;
   })

   $("#l-wit").click(function () {
      material.color.setHex(0xFFFFFF)
      material.needsUpdate = true;
   })

   $("#l-grijs").click(function () {
      material.color.setHex(0x808080)
      material.needsUpdate = true;
   })


   // Disable Logo
   $("#t-moogue").click(function () {
      scene.remove(m)
      scene.remove(s)
   })

   $("#t-herfst").click(function () {
      scene.remove(m)
      scene.remove(s)
   })

   $("#t-kikker").click(function () {
      scene.remove(m)
      scene.remove(s)
   })

   $("#l-no").click(function () {
      scene.remove(m)
      scene.remove(s)
   })

   $("#e-reset").click(function () {
      material.map = texture1
      material.normalMap = decalmooNormal
      material.bumpMap = decalmooBump
      material.needsUpdate = true;
   })


})


nyc.click(function() {

   console.log(this.id)
   
   const decalMaterial = new THREE.MeshPhongMaterial({
         map: texture2,
         normalMap: decalnycNormal,
         normalScale: new THREE.Vector2(1, 1),
         bumpMap: decalnycBump,
      specular: 0x444400,
      shininess: 25,
      transparent: true,
      depthTest: true,
      depthWrite: true,
      polygonOffset: true,
      polygonOffsetFactor: -4,
      wireframe: false,
   });

function changingPosition(positioning) {
      if (positioning.matches) { // If media query matches
      const positioning = new THREE.Vector3(0.5, 0.925, -0.005);

   
      return positioning
      } else {
   
         const positioning = new THREE.Vector3(0.8, 0.66, 0.0014);

      return positioning
      }
   
   }

   var positioning = window.matchMedia("(max-width: 480px)")
   changingPosition(positioning) // Call listener function at run time
   positioning.addListener(changingPosition) // Attach listener function on state changes


   const position = changingPosition(positioning)
   const orientation = new THREE.Euler(-1.57, -1.57, -1.57, 'XYZ');
   const size = new THREE.Vector3(0.5, 0.5, 0.5);

   const material = decalMaterial.clone();
      material.color.setHex(0xffffff);

   const m = new THREE.Mesh(new DecalGeometry(loadedModel.children[6], position, orientation, size), material);
   const s = new THREE.Mesh(new DecalGeometry(loadedModel.children[0], position, orientation, size), material);

   const decals = [];
      decals.push(m);
         scene.add(m);

      decals.push(s);
         scene.add(s);
         
   $("#l-moogue").click(function () {
      material.map = texture1
      material.normalMap = decalmooNormal
      material.bumpMap = decalmooBump
      material.needsUpdate = true;                                    
   })

   $("#l-nyc").click(function () {
      material.map = texture2
      material.normalMap = decalnycNormal
      material.bumpMap = decalnycBump
      material.needsUpdate = true;
   })

   // Logo Colors
   $("#l-zwart").click(function () {
      material.color.setHex(0x0d0d0d)
      material.needsUpdate = true;
   })

   $("#l-wit").click(function () {
      material.color.setHex(0xFFFFFF)
      material.needsUpdate = true;
   })

   $("#l-grijs").click(function () {
      material.color.setHex(0x808080)
      material.needsUpdate = true;
   })


   // Disable Logo
   $("#t-moogue").click(function () {
      scene.remove(m)
      scene.remove(s)
   })

   $("#t-herfst").click(function () {
      scene.remove(m)
      scene.remove(s)
   })

   $("#t-kikker").click(function () {
      scene.remove(m)
      scene.remove(s)
   })

   $("#l-no").click(function () {
      scene.remove(m)
      scene.remove(s)
   })

   $("#e-reset").click(function () {
      material.map = texture1
      material.normalMap = decalmooNormal
      material.bumpMap = decalmooBump
      material.needsUpdate = true;
   })
})           

$("#t-moogue").click(function (){
   mooguelogo()
   })



function mooguelogo() {

   const decalMaterial = new THREE.MeshPhongMaterial({
      specular: 0x444400,
         map: texture1,
         normalMap: decalmooNormal,
         normalScale: new THREE.Vector2(1, 1),
         bumpMap: decalmooBump,
      shininess: 50,
      transparent: true,
      depthTest: true,
      depthWrite: false,
      polygonOffset: true,
      polygonOffsetFactor: - 4,
      wireframe: false,
      // side: THREE.FrontSide
   });
   

   const decals = [];

   function changingPosition(positioning) {
      if (positioning.matches) { // If media query matches
      const positioning = new THREE.Vector3(0.5, 0.925, -0.005);

   
      return positioning
      } else {
   
         const positioning = new THREE.Vector3(0.8, 0.66, 0.0014);

      return positioning
      }
   
   }

   var positioning = window.matchMedia("(max-width: 480px)")
   changingPosition(positioning) // Call listener function at run time
   positioning.addListener(changingPosition) // Attach listener function on state changes


   const position = changingPosition(positioning)
   const orientation = new THREE.Euler(-1.57, -1.57, -1.57, 'XYZ');
   const size = new THREE.Vector3(0.5, 0.5, 0.5);

   const material = decalMaterial.clone();
   material.color.setHex(0xffffff);

   const m = new THREE.Mesh(new DecalGeometry(loadedModel.children[6], position, orientation, size), material);
   const s = new THREE.Mesh(new DecalGeometry(loadedModel.children[0], position, orientation, size), material);

   decals.push(m);
      scene.add(m);

   decals.push(s);
      scene.add(s);

      $("#l-moogue").click(function () {
         material.map = texture1
         material.normalMap = decalmooNormal
         material.bumpMap = decalmooBump
         material.needsUpdate = true;
   })

   $("#l-nyc").click(function () {
         material.map = texture2
         material.normalMap = decalnycNormal
         material.bumpMap = decalnycBump
         material.needsUpdate = true;
   })

   // Logo Colors
   $("#l-zwart").click(function () {
         material.color.setHex(0x0d0d0d)
         material.needsUpdate = true;
   })

   $("#l-wit").click(function () {
         material.color.setHex(0xFFFFFF)
         material.needsUpdate = true;
   })

   $("#l-grijs").click(function () {
         material.color.setHex(0x808080)
         material.needsUpdate = true;
   })

   // Disable Logo
   $("#l-nyc").click(function () {
      scene.remove(m)
      scene.remove(s)
   })

   $("#l-moogue").click(function () {
      scene.remove(m)
      scene.remove(s)
   })
   
   $("#t-herfst").click(function () {
      scene.remove(m)
      scene.remove(s)
   })

   $("#t-kikker").click(function () {
      scene.remove(m)
      scene.remove(s)
   })

   $("#l-no").click(function () {
      scene.remove(m)
      scene.remove(s)
   })

   // Optional - Reset Button
   $("#e-reset").click(function () {
      material.map = texture1
      material.normalMap = decalmooNormal
      material.bumpMap = decalmooBump
      material.needsUpdate = true;
   })} 
              



  /***************
     Thema Buttons
  ***************/


/********************** 

1 = Visor
2 = Front
3 = Side
4 = Top Button
5 =  Stitches
6 = Grown Stitches
7 = Sweatband

               1          2          3          4          5          6          7
 moogue = ["#00AAED", "#002647", "#002647", "#00AAED", "#002647", "#00AAED", "#00AAED"]
 herfst = ["#CB6015", "#660300", "#E0A526", "#CB6015", "#660300", "#CB6015", "#CB6015"]
 kikker = ["#409140", "#0F3325", "#0F3325", "#409140", "#0F3325", "#409140", "#409140"]

**********************/


var themaID1 = "t-moogue"
document.getElementById('t-moogue').onclick= () =>{thema1(themaID1)}
function thema1(themaID1){
   switch(themaID1){
      case 't-moogue':
         const colorArray = ["#00AAED", "#002647", "#002647", "#00AAED", "#002647", "#00AAED", "#00AAED"]
         var selectedColor = "";
            for (let i = 0; i < colorArray.length; i++){
               selectedColor = colorArray[i];
               changeChildrenID(i); 
               klep(selectedColor, childrenID);
               }
                 
               const radioGroups = {}
               const colorlb = document.getElementById('k-lichtblauw')
               const logom = document.getElementById('l-moogue')
               const logon = document.getElementById('l-nyc')
               const logokleurb = document.getElementById('l-zwart')
               const logokleurw = document.getElementById('l-wit')
               const logokleurg = document.getElementById('l-grijs')
               const themem = document.getElementById('t-moogue')

               document.querySelectorAll('[data-radio-name]').forEach( el => {
                 const name = el.dataset.radioName;
                 const value = el.dataset.radioValue;
                 const selected = el.dataset.radioSelected === 'true';
           

                 // Register radio
                 radioGroups[name] = radioGroups[name] || {
                    radios: [],
                    selected: null
                 };
              
                 radioGroups[name].radios.push(el);
              
                 if ( selected && radioGroups[name].selected == null ) {
                    radioGroups[name].selected = value;
                 }
                 
                 radioGroups[name].radios.forEach( el => {
                    el.dataset.radioSelected = 'false';

                    colorlb.dataset.radioSelected = 'true';

                     themem.dataset.radioSelected = 'true'

                    logokleurb.dataset.radioSelected = 'false';
                    logokleurw.dataset.radioSelected = 'true';
                    logokleurg.dataset.radioSelected = 'false';
              
                    logom.dataset.radioSelected = 'true';
                    logon.dataset.radioSelected = 'false';
        
                    document.getElementById("l-zwart").disabled = false;
                    document.getElementById("l-wit").disabled = false;
                    document.getElementById("l-grijs").disabled = false;
                 })})
               
               $("#parts").html(parts[0]);
               count = 0
                 switch(count){
                    case 0:
                       childrenID = 2
                    break;
                 }
                 $("#leftb").css("display", "none")
                 $("#rightb").css("display", "block")
                 $("#rightb").click(function() {
                 $("#leftb").css("display", "block")
                 })
            break;
   }
}

var themaID2 = "t-herfst"
document.getElementById('t-herfst').onclick= () =>{thema2(themaID2)}
 function thema2(themaID2){
    switch(themaID2){
       case 't-herfst':
          const colorArray = ["#CB6015", "#660300", "#E0A526", "#CB6015", "#660300", "#CB6015", "#CB6015"]
          var selectedColor = "";
             for (let i = 0; i < colorArray.length; i++){
                selectedColor = colorArray[i];
                changeChildrenID(i); 
                klep(selectedColor, childrenID);
                }

               const radioGroups = {}

               const themeh = document.getElementById('t-herfst')
               const loff = document.getElementById('l-no')
               const coloror = document.getElementById('k-oranje')

               document.querySelectorAll('[data-radio-name]').forEach( el => {
                 const name = el.dataset.radioName;
                 const value = el.dataset.radioValue;
                 const selected = el.dataset.radioSelected === 'true';
           

                 // Register radio
                 radioGroups[name] = radioGroups[name] || {
                    radios: [],
                    selected: null
                 };
              
                 radioGroups[name].radios.push(el);
              
                 if ( selected && radioGroups[name].selected == null ) {
                    radioGroups[name].selected = value;
                 }
                 
                 radioGroups[name].radios.forEach( el => {
                    el.dataset.radioSelected = 'false';

                    coloror.dataset.radioSelected = 'true';

                    themeh.dataset.radioSelected = 'true'
                  
                    loff.dataset.radioSelected = 'true'

                    document.getElementById("l-zwart").disabled = true;
                    document.getElementById("l-wit").disabled = true;
                    document.getElementById("l-grijs").disabled = true;
                 })})

                $("#parts").html(parts[0]);
                count = 0
                  switch(count){
                     case 0:
                        childrenID = 2
                     break;
                  }
                  $("#leftb").css("display", "none")
                  $("#rightb").css("display", "block")
                  $("#rightb").click(function() {
                  $("#leftb").css("display", "block")
                  })
             break;
    }
 }

 var themaID3 = "t-kikker"
 document.getElementById('t-kikker').onclick= () =>{thema3(themaID3)}
  function thema3(themaID3){
     switch(themaID3){
        case 't-kikker':
           const colorArray = ["#409140", "#0F3325", "#0F3325", "#409140", "#0F3325", "#409140", "#409140"]
           var selectedColor = "";
              for (let i = 0; i < colorArray.length; i++){
                 selectedColor = colorArray[i];
                 changeChildrenID(i); 
                 klep(selectedColor, childrenID);
                 }

                 const radioGroups = {}

                 const themek = document.getElementById('t-kikker')
                 const loff = document.getElementById('l-no')
                 const colorlg = document.getElementById('k-lichtgroen')
  
                 document.querySelectorAll('[data-radio-name]').forEach( el => {
                   const name = el.dataset.radioName;
                   const value = el.dataset.radioValue;
                   const selected = el.dataset.radioSelected === 'true';
             
  
                   // Register radio
                   radioGroups[name] = radioGroups[name] || {
                      radios: [],
                      selected: null
                   };
                
                   radioGroups[name].radios.push(el);
                
                   if ( selected && radioGroups[name].selected == null ) {
                      radioGroups[name].selected = value;
                   }
                   
                   radioGroups[name].radios.forEach( el => {
                      el.dataset.radioSelected = 'false';
  
                      colorlg.dataset.radioSelected = 'true';
  
                      themek.dataset.radioSelected = 'true'
                    
                      loff.dataset.radioSelected = 'true'
  
                      document.getElementById("l-zwart").disabled = true;
                      document.getElementById("l-wit").disabled = true;
                      document.getElementById("l-grijs").disabled = true;
                   })})

                 $("#parts").html(parts[0]);
                  count = 0
                    switch(count){
                       case 0:
                          childrenID = 2
                       break;
                    }
                    $("#leftb").css("display", "none")
                    $("#rightb").css("display", "block")
                    $("#rightb").click(function() {
                    $("#leftb").css("display", "block")
                    })
              break;
     }
  }
  

  /*****************
     Extra Knoppen
  *****************/


     // Reset Button
  var resetID = "e-reset"
  document.getElementById('e-reset').onclick= () =>{reset(resetID)}
   function reset(resetID){
      switch(resetID){
         case 'e-reset':
            const colorArray = ["#00AAED", "#002647", "#002647", "#00AAED", "#002647", "#00AAED", "#00AAED"]
            var selectedColor = "";
               for (let i = 0; i < colorArray.length; i++){
                  selectedColor = colorArray[i];
                  changeChildrenID(i); 
                  klep(selectedColor, childrenID);
                  }
                  
                  $("#parts").html(parts[0]);
                  count = 0
                    switch(count){
                       case 0:
                          childrenID = 2
                       break;
                    }

                    mooguelogo()

                    $("#leftb").css("display", "none")
                    $("#rightb").css("display", "block")
                    $("#rightb").click(function() {
                    $("#leftb").css("display", "block")
                    })
                  break;

      }
   }

   // Get the checkbox
   $("#rotation").click(function() {

      var rotationCheckbox = document.getElementById("rotation");

      // If the checkbox is checked, display the output text
      if (rotationCheckbox.checked == true){
         controls.autoRotate = true; 

      } else {
         controls.autoRotate = false; 
      }
   })


   
  /********************
     Lighting / Colors   
  ********************/


     function ColorLighting() {

     // Set background
     scene.background = new THREE.Color( 0xf1f1f1 );

     // Add lights
     var hemiLight1 = new THREE.HemisphereLight( 0xffffff, 0xffffff, 0.495 );
        hemiLight1.position.set( 0, 50, 0 );

        var hemiLight2 = new THREE.HemisphereLight( 0xffffff, 0xffffff, 0.495 );
        hemiLight2.position.set( 0, 50, 0 );

           var hemiLight3 = new THREE.HemisphereLight( 0xffffff, 0xffffff, 0.525 );
           hemiLight3.position.set( 0, 50, 0 );
     
     // Add hemisphere light to scene   
     scene.add( hemiLight1 );

     var dirLight1 = new THREE.DirectionalLight( 0xf1f1f1, 0.535 );
        dirLight1.position.set( -0.5, 7.5, 5 );
        dirLight1.castShadow = true;
        dirLight1.shadow.camera.far = 500;
        dirLight1.shadow.mapSize = new THREE.Vector2(2048, 2048);

        var dirLight2 = new THREE.DirectionalLight( 0x6a6a6a, 0.535 );
           dirLight2.position.set( -0.5, 7.5, 5 );
           dirLight2.castShadow = true;
           dirLight2.shadow.mapSize = new THREE.Vector2(2048, 2048);//2048, 2048);

           var dirLight3 = new THREE.DirectionalLight( 0xffffff, 0.53 );
              dirLight3.position.set( -0.5, 7.5, 5 );
              dirLight3.castShadow = true;
              dirLight3.shadow.mapSize = new THREE.Vector2(2048, 2048); 
        
     // Add directional Light to scene    
        scene.add( dirLight1 );

     // Floor
     var floorGeometry = new THREE.PlaneGeometry(5000, 5000, 1, 1);
     var floorMaterial = new THREE.MeshPhongMaterial({
     color: 0xf1f1f1, // This color is manually dialed in to match the background color
     shininess: 0
     });


     var floor = new THREE.Mesh(floorGeometry, floorMaterial);
     floor.rotation.x = -0.5 * Math.PI;
     floor.receiveShadow = true;
     // floor.position.y = -0.5;
     scene.add(floor);


     $("#color-w").click(function () {
        floorMaterial.color.setHex( 0xf1f1f1 )
        scene.background.set( 0xf1f1f1 )

        scene.remove(dirLight2)
        scene.remove(hemiLight2)
        scene.remove(dirLight3)
        scene.remove(hemiLight3)

        scene.add(dirLight1)
        scene.add(hemiLight1)

     })

     $("#color-g").click(function () {
        floorMaterial.color.setHex(0x242424)
        scene.background.set( 0x595959 )

        scene.remove(dirLight1)
        scene.remove(hemiLight1)
        scene.remove(dirLight3)
        scene.remove(hemiLight3)

        scene.add(dirLight2)
        scene.add(hemiLight2)
     })  

     $("#color-r").click(function () {
        floorMaterial.color.setHex(0xca6944)
        scene.background.set( 0xe0ad90 )

        scene.remove(dirLight1)
        scene.remove(hemiLight1)
        scene.remove(dirLight2)
        scene.remove(hemiLight2)

        scene.add(dirLight3)
        scene.add(hemiLight3)
                    
     })


   //   settings()
   
      // Get the checkbox
      $("#shadow").click(function() {
         
    
      var shadowCheckbox = document.getElementById("shadow");

      // If the checkbox is checked, display the output text
      if (shadowCheckbox.checked == true){
         dirLight1.castShadow = true;
         dirLight2.castShadow = true;
         dirLight3.castShadow = true;
      } else {
         dirLight1.castShadow = false;
         dirLight2.castShadow = false;
         dirLight3.castShadow = false;
      }
      })

  
  function ResizeM(mobiel) {
     if (mobiel.matches) { // If media query matches
        floor.position.y = 0;
     }else {
        floor.position.y = -0.5;
     }
  }

   var mobiel = window.matchMedia("(max-width: 480px)")
   ResizeM(mobiel) // Call listener function at run time
   mobiel.addListener(ResizeM) // Attach listener function on state changes
  }


  /**********
     Ipad
  **********/  


     function ResizeL(laptop) {
        if (laptop.matches) { // If media query matches
           camera.position.set( 3.2, 0.5, 0.5)
  
           controls.minDistance = 1.6;
           controls.maxDistance = 3;
           controls.maxPolarAngle = 1.55;
           controls.minPolarAngle = 1.1;
        } 
        else {
         return;
        }
      }
  
      var laptop = window.matchMedia("(max-width: 1024px)")
      ResizeL(laptop) // Call listener function at run time
      laptop.addListener(laptop) // Attach listener function on state changes
  

     // Resize 
   function ResizeI(ipad) {
     if (ipad.matches) { // If media query matches
        camera.position.set( 3, 0.6, 0.5)

        controls.minDistance = 2;
        controls.maxDistance = 3;
        controls.maxPolarAngle = 1.5;
        controls.minPolarAngle = 1.1;
     } 
     else {
      return;
     }

   }

   var ipad = window.matchMedia("(max-width: 768px)")
   ResizeI(ipad) // Call listener function at run time
   ipad.addListener(ResizeI) // Attach listener function on state changes


  /**********
     Mobile
  **********/  


     // Resize 

    function ResizeM(mobiel) {
     if (mobiel.matches) { // If media query matches
        camera.position.set( 3.2, 0, 2)

        controls.minDistance = 2.25;
        controls.maxDistance = 3.5;
        controls.maxPolarAngle = 1.55;
        controls.minPolarAngle = 0.95;
     }
     else {
      return;
     }
    }

   var mobiel = window.matchMedia("(max-width: 480px)")
   ResizeM(mobiel) // Call listener function at run time
   mobiel.addListener(ResizeM) // Attach listener function on state changes


  /**********
     Overige
  **********/  
    

  /**********
     Console 
  **********/  


//    parts.forEach((parts, count) => {
//    console.log(`the index for ${ parts } is ${ count }`);
//   });

//    document.getElementById('leftb').onclick = function() {
//    console.log("1 achteruit");
//    };

//    document.getElementById('rightb').onclick = function() {
//       console.log("1 vooruit");
//    };   

        
  window.addEventListener("resize", function(event) {
     console.log(document.body.clientWidth + ' wide by ' + document.body.clientHeight+' high');
  })

  window.addEventListener('DOMContentLoaded', (event) => {
     console.log('DOM fully loaded and parsed');
 });

 console. log(THREE. REVISION)


  /**********
     Helpers
  **********/

     // const spotLightHelper = new THREE.SpotLightHelper( spotLight );
     // scene.add( spotLightHelper );

     // const hemisphereLightHelper = new THREE.HemisphereLightHelper(hemiLight, 0.2)
     // scene.add(hemisphereLightHelper)

     // const directionalLightHelper = new THREE.DirectionalLightHelper(dirLight, 0.2)
     // scene.add(directionalLightHelper)

     // Axes Helper
     // const axesHelper = new THREE.AxesHelper(2)
     // scene.add(axesHelper)

     /*******************
      * X axis is Red
      * Y axis is Green
      * Z axis is Blue
     *******************/


  /*******************
     Debug GUI W.I.P.
  *******************/


//   const gui = new lil.GUI({ closed: true, width: 225})


//   var folder1 = gui.addFolder('Environment')
//   var folder2 = gui.addFolder('Lightning')
//   var folder3 = gui.addFolder('Settings')   
  

//   folder1.add(floor, 'visible').name('Floor-Showing');

//   folder2.add(dirLight1, "intensity", 0, 2, 0.01).name('DirLight-Intensity');
//   folder2.add(hemiLight1, "intensity", 0, 2, 0.01).name('hemiLight-Intensity');
// //   folder2.add(ambientLight, "intensity", 0, 2, 0.01).name('ambientLight-Intensity');

//   folder3.add( document.body.appendChild(stats.dom)  )

//   gui.add(modelMaterial, 'metalness').min(0).max(1).step(0.0001)
//   gui.add(modelMaterial, 'roughness').min(0).max(1).step(0.0001)


         
}