document.addEventListener('DOMContentLoaded', () => {
  const radioGroups = {}

  document.querySelectorAll('[data-radio-name]').forEach( el => {
    const name = el.dataset.radioName;
    const value = el.dataset.radioValue;
    const selected = el.dataset.radioSelected === 'true';

    // Specifieke buttons

    // Colors buttons
    const colorlb = document.getElementById('k-lichtblauw')
      const coloror = document.getElementById('k-oranje')
        const colorlg = document.getElementById('k-lichtgroen')
          const colordk = document.getElementById('k-donkergroen')
            const colorge = document.getElementById('k-geel')
              const colorro = document.getElementById('k-rood')

    // Reset
    const reset = document.getElementById('e-reset')
        const loff = document.getElementById('l-no')

    // Logo
    const logom = document.getElementById('l-moogue')
      const logon = document.getElementById('l-nyc')

    const logokleurb = document.getElementById('l-zwart')
      const logokleurw = document.getElementById('l-wit')
        const logokleurg = document.getElementById('l-grijs')

    // Theme
    const themem = document.getElementById('t-moogue')
      const themeh = document.getElementById('t-herfst')
        const themek = document.getElementById('t-kikker')

    // Register radio
    radioGroups[name] = radioGroups[name] || {
      radios: [],
      selected: null
    };

    radioGroups[name].radios.push(el);

    if ( selected && radioGroups[name].selected == null ) {
      radioGroups[name].selected = value;
    }

    // attach listeners
    el.addEventListener('click', () => {
      radioGroups[name].radios.forEach( el => {
        el.dataset.radioSelected = 'false';
      })

      el.dataset.radioSelected = 'true';
      radioGroups[name].selected = value;
     })



      /*********************
        Logo Knoppen
      *********************/



      $("#l-moogue").click(function () {
        logokleurb.dataset.radioSelected = 'false';
        logokleurw.dataset.radioSelected = 'true';
        logokleurg.dataset.radioSelected = 'false';

        themem.dataset.radioSelected = 'false'
        themeh.dataset.radioSelected = 'false'
        themek.dataset.radioSelected = 'false'
      })
    
      $("#l-nyc").click(function () {
        logokleurb.dataset.radioSelected = 'false';
        logokleurw.dataset.radioSelected = 'true';
        logokleurg.dataset.radioSelected = 'false';

        themem.dataset.radioSelected = 'false'
        themeh.dataset.radioSelected = 'false'
        themek.dataset.radioSelected = 'false'
      })


      
      /*********************
        Reset Knoppen
      *********************/



        reset.addEventListener('click', () => {
          radioGroups[name].radios.forEach( el => {
            el.dataset.radioSelected = 'false';
            
            logom.dataset.radioSelected = 'true';
            logokleurw.dataset.radioSelected = 'true';

            themem.dataset.radioSelected = 'true'

            colorlb.dataset.radioSelected = 'true'

            document.getElementById("l-zwart").disabled = false;
            document.getElementById("l-wit").disabled = false;
            document.getElementById("l-grijs").disabled = false;
        })})
        


        // loff.addEventListener('click', () => {
        //   radioGroups[name].radios.forEach( el => {
        //     logokleurb.dataset.radioSelected = 'false';
        //     logokleurw.dataset.radioSelected = 'false';
        //     logokleurg.dataset.radioSelected = 'false';

        //     themem.dataset.radioSelected = 'false'
        // })})



      /*********************
        Thema Knoppen
      *********************/
        

      //  $("#t-herfst").click(function () {
      //     el.dataset.radioSelected = 'false';
      //     loff.dataset.radioSelected = 'true';
          
      //     coloror.dataset.radioSelected = 'true';

      //     logokleurb.dataset.radioSelected = 'false';
      //     logokleurw.dataset.radioSelected = 'false';
      //     logokleurg.dataset.radioSelected = 'false';

      //     logom.dataset.radioSelected = 'false';
      //     logon.dataset.radioSelected = 'false';

      //     themem.dataset.radioSelected = 'false'

      //     document.getElementById("l-zwart").disabled = true;
      //     document.getElementById("l-wit").disabled = true;
      //     document.getElementById("l-grijs").disabled = true;
      // })
            
      // $("#t-kikker").click(function () {
      //   el.dataset.radioSelected = 'false';
      //   loff.dataset.radioSelected = 'true'
        
      //   colorlg.dataset.radioSelected = 'true'

      //   logokleurb.dataset.radioSelected = 'false';
      //   logokleurw.dataset.radioSelected = 'false';
      //   logokleurg.dataset.radioSelected = 'false';

      //   logom.dataset.radioSelected = 'false';
      //   logon.dataset.radioSelected = 'false';

      //   themem.dataset.radioSelected = 'false'

      //   document.getElementById("l-zwart").disabled = true;
      //   document.getElementById("l-wit").disabled = true;
      //   document.getElementById("l-grijs").disabled = true;
      // })
 })
})
